import React from 'react'
import styled from 'styled-components'

import { Heading, Section } from '../../styles/styled-components'

import { skills } from '../../data/skills'

const SkillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
`

const SkillCard = styled.div`
  background: ${({ theme: { colors } }) => colors.charcoal};
  color: ${({ theme: { colors } }) => colors.lemon};
  border-radius: 100%;
  margin: 20px;
  height: 15rem;
  width: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 350px) {
    height: 10rem;
    width: 10rem;
    background: none;
    color: ${({ theme: { colors } }) => colors.charcoal};
  }
`

const Icon = styled.span`
  height: 5rem;
  width: 5rem;
  border-radius: 5px;
  margin: 1rem 0;
`

const Skills = () => {
  return (
    <Section>
      <Heading>Skills</Heading>
      <SkillContainer>
        {skills.map((skill) => (
          <SkillCard key={skill.id}>
            <Icon
              className='iconify'
              data-icon={skill.icon}
              data-inline='false'
            ></Icon>
            <h4>{skill.name}</h4>
          </SkillCard>
        ))}
      </SkillContainer>
    </Section>
  )
}

export default Skills
