import tipsease from '../img/portfolio/tipsease.jpg';
import hostify from '../img/portfolio/hostify.png';
import xivMinions from '../img/portfolio/xiv-minions.jpg';

export const portfolio = [
    {
        id: 1,
        name: 'Tipsease',
        image: tipsease,
        skills: ['HTML', 'CSS', 'Less'],
        liveDemo: 'https://lambdatipsease.netlify.com',
        github: 'https://github.com/Build-Week-Tipsease/bianca_tipsease_marketingPage',
        description: 'Worked with an international team of Lambda students in different stages of their tracks. As the role of UI Developer, used HTML, CSS, and Less to build a marketing website for an app that the rest of the team worked on.'
    },
    {
        id: 2,
        name: 'Hostify',
        image: hostify,
        skills: ['JavaScript', 'React', 'MaterialUI'],
        liveDemo: 'https://hostifyapp.netlify.com',
        github: 'https://github.com/bw-airbnbprice3/front-end',
        description: 'Coordinated with a cross-functional team of eight to deliver an app that allows an AirBnB host to input their listings and receive an optimized price based on the information given. Worked on the front-end using ReactJS to implement server requests, route management, and form management, and MaterialUI to style the app.'
    },
    {
        id: 3,
        name: 'FFXIV Minions',
        image: xivMinions,
        skills: ['JavaScript', 'React', 'Redux'],
        liveDemo: 'https://xiv-minions.firebaseapp.com',
        github: 'https://github.com/bseverino/React-Redux-App/tree/master/xiv-minions',
        description: 'Lambda project utilizing React and Redux to fetch data from XIVAPI. Used data to display a list of "minions" from the game Final Fantasy XIV, each linking to a page with descriptions and flavor text. Styled with Reactstrap.'
    }
];