import React from 'react'
import { Heading, Section } from '../../styles/styled-components'

const About = () => {
  return (
    <Section>
      <Heading>About</Heading>
      <p>
        &gt; Bianca Severino is a software engineer and digital artist working
        out of Connecticut, USA. They have been training and working as a visual
        artist since 2010, and attended Lambda School's web development and
        computer science program from 2019 to 2020. They are currently interning
        at edX, and have a special interest in front end web development.
      </p>
    </Section>
  )
}

export default About
