import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap'
import styled from 'styled-components'

const StickyNavBar = styled.div`
  position: absolute;
  top: 0;
  min-height: 7.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  background: ${({ theme: { colors } }) => colors.charcoal};
`

const MobileNavbar = styled(Navbar)`
  display: none;

  @media (max-width: 500px) {
    display: block;
  }
`

const NavItems = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media (max-width: 1000px) {
    width: 50%;
  }
  @media (max-width: 700px) {
    width: 80%;
  }
  @media (max-width: 500px) {
    display: none;
  }
`

const MobileNavItems = styled(Nav)`
  background: ${({ theme: { colors } }) => colors.charcoal};
  padding: 2rem;
`

const NavLink = styled(Link)`
  color: ${({ theme: { colors } }) => colors.coral};

  &:hover {
    color: ${({ theme: { colors } }) => colors.tangerine};
  }

  @media (max-width: 500px) {
    margin: 2rem 0;
  }
`

const NavBar = () => {
  const [collapsed, setCollapsed] = useState(true)

  const toggleNavbar = () => setCollapsed(!collapsed)

  return (
    <>
      <StickyNavBar>
        <NavItems>
          <NavLink to='/'>Home</NavLink>
          <NavLink to='/about'>About</NavLink>
          <NavLink to='/skills'>Skills</NavLink>
          <NavLink to='/portfolio'>Portfolio</NavLink>
          <NavLink to='/contact'>Contact</NavLink>
        </NavItems>
      </StickyNavBar>

      <MobileNavbar color='faded' dark>
        <NavbarToggler onClick={toggleNavbar} className='mr-2' />
        <Collapse isOpen={!collapsed} navbar>
          <MobileNavItems navbar>
            <NavLink to='/' onClick={toggleNavbar}>
              &gt; Home
            </NavLink>
            <NavLink to='/about' onClick={toggleNavbar}>
              &gt; About
            </NavLink>
            <NavLink to='/skills' onClick={toggleNavbar}>
              &gt; Skills
            </NavLink>
            <NavLink to='/portfolio' onClick={toggleNavbar}>
              &gt; Portfolio
            </NavLink>
            <NavLink to='/contact' onClick={toggleNavbar}>
              &gt; Contact
            </NavLink>
          </MobileNavItems>
        </Collapse>
      </MobileNavbar>
    </>
  )
}

export default NavBar
