import React from 'react'
import styled from 'styled-components'
import { fadeIn } from '../../styles/styled-components/animations/fadeIn'

const Heading = styled.div`
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 1s linear;

  @media (max-width: 500px) {
    height: 50vh;
  }
`

const Title = styled.h1`
  color: ${({ theme: { colors } }) => colors.charcoal};
  font-family: ${({ theme: { fonts } }) => fonts[0]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.extraLarge};
  text-shadow: ${({ theme: { colors } }) => `1px 1px 25px ${colors.lemon}`};

  @media (max-width: 600px) {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.large};
  }

  @media (max-width: 400px) {
    font-size: 5rem;
  }

  @media (max-width: 333px) {
    font-size: 4.5rem;
  }
`

const Subtitle = styled.h2`
  color: ${({ theme: { colors } }) => colors.lemon};
  font-family: ${({ theme: { fonts } }) => fonts[0]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.medium};

  @media (max-width: 600px) {
    font-size: ${({ theme: { fontSizes } }) => fontSizes.small};
  }
`

const Home = () => {
  return (
    <Heading>
      <Title>Bianca Severino</Title>
      <Subtitle>> Software Engineer</Subtitle>
    </Heading>
  )
}

export default Home
