import React from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'

import Theme from '../styles/styled-components/Theme'

import NavBar from './global/NavBar'
import Footer from './global/Footer'

import Home from './pages/Home'
import About from './pages/About'
import Skills from './pages/Skills'
import Portfolio from './pages/Portfolio'
import Contact from './pages/Contact'

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: ${({ theme: { colors } }) =>
    `linear-gradient(to bottom, ${colors.lemon}, ${colors.creamsicle}, ${colors.grape}, ${colors.violet}, ${colors.royal})`};
`

const Container = styled.div`
  width: 60%;
  margin: 13rem auto 15rem;
  color: ${({ theme: { colors } }) => colors.charcoal};

  @media (max-width: 1200px) {
    width: 80%;
  }

  @media (max-width: 500px) {
    margin: 5rem auto 10rem;
    width: 90%;
  }
`

function App() {
  return (
    <Theme>
      <Wrapper>
        <NavBar />

        <Container>
          <Route exact path='/' component={Home} />
          <Route path='/about' component={About} />
          <Route path='/skills' component={Skills} />
          <Route path='/portfolio' component={Portfolio} />
          <Route path='/contact' component={Contact} />
        </Container>

        <Footer />
      </Wrapper>
    </Theme>
  )
}

export default App
