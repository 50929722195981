import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/css/index.css'
import App from './components'

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
)
