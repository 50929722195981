import styled from 'styled-components'
import { fadeIn } from '../animations/fadeIn'

export const Section = styled.div`
  background-color: rgba(255, 255, 255, 0.25);
  width: 100%;
  min-height: 65vh;
  padding: 50px;
  border-radius: 10px;
  animation: ${fadeIn} 1s linear;
`
