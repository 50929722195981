import React from 'react'
import styled from 'styled-components'

import { Heading, Section } from '../../styles/styled-components'

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5rem;
`

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;

  @media (max-width: 1000px) {
    width: 100%;
  }
`

const FormInput = styled.input`
  width: 100%;
  border: none;
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 1rem;
`

const FormText = styled.textarea`
  width: 100%;
  min-height: 12rem;
  border: none;
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 1rem;
`

const Button = styled.button`
  background: ${({ theme: { colors } }) => colors.charcoal};
  color: ${({ theme: { colors } }) => colors.coral};
  width: 13rem;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.charcoal};
    background: ${({ theme: { colors } }) => colors.coral};
  }
`

const Contact = () => {
  return (
    <Section>
      <Heading>Contact</Heading>
      <FormContainer>
        <ContactForm
          action='https://formspree.io/mveroznx'
          method='POST'
          target='_blank'
        >
          <FormInput type='text' name='name' placeholder='name' />
          <FormInput type='text' name='email' placeholder='email' />
          <FormText name='message' placeholder='message' />
          <Button type='submit' value='submit'>
            Submit
          </Button>
        </ContactForm>
      </FormContainer>
    </Section>
  )
}

export default Contact
