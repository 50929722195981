import React from 'react'
import { ThemeProvider } from 'styled-components'

const theme = {
  colors: {
    //light
    white: '#FFF',
    thistle: '#F1DAFF',
    //medium
    coral: '#FF8A8B',
    tangerine: '#FB4142',
    lemon: '#feda84',
    creamsicle: '#ff9b83',
    grape: '#976393',
    violet: '#685489',
    royal: '#43457f',
    //dark
    charcoal: '#17202A',
  },
  fonts: ["'Roboto Mono', monospace", "'Open Sans', sans-serif"],
  fontSizes: {
    small: '1.8rem',
    medium: '3rem',
    large: '4.5rem',
    extraLarge: '6rem',
  },
}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
