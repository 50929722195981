import React from 'react'
import styled from 'styled-components'

const StickyFooter = styled.div`
  position: absolute;
  bottom: 0;
  height: 7.5rem;
  width: 100%;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.coral};
  background: ${({ theme: { colors } }) => colors.charcoal};
`

const Social = styled.div`
  width: 10rem;
  display: flex;
  justify-content: space-between;
`

const Link = styled.a`
  color: ${({ theme: { colors } }) => colors.lemon};

  &:hover {
    color: ${({ theme: { colors } }) => colors.grape};
  }
`

const Footer = () => {
  return (
    <StickyFooter>
      <Social>
        <Link href='https://github.com/bseverino'>
          <span
            className='iconify'
            data-icon='simple-icons:github'
            data-inline='false'
          ></span>
        </Link>
        <Link href='https://twitter.com/bseverin0'>
          <span
            className='iconify'
            data-icon='simple-icons:twitter'
            data-inline='false'
          ></span>
        </Link>
        <Link href='https://linkedin.com/in/biancasev'>
          <span
            className='iconify'
            data-icon='simple-icons:linkedin'
            data-inline='false'
          ></span>
        </Link>
      </Social>
      <p>© Bianca Severino 2019-2020</p>
    </StickyFooter>
  )
}

export default Footer
