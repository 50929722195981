export const skills = [
  {
    id: 1,
    name: 'HTML',
    icon: 'entypo:code',
  },
  {
    id: 2,
    name: 'CSS',
    icon: 'simple-icons:css3',
  },
  {
    id: 3,
    name: 'JavaScript',
    icon: 'simple-icons:javascript',
  },
  {
    id: 4,
    name: 'React',
    icon: 'simple-icons:react',
  },
  {
    id: 5,
    name: 'Redux',
    icon: 'simple-icons:redux',
  },
  {
    id: 6,
    name: 'Node',
    icon: 'simple-icons:node-dot-js',
  },
  {
    id: 7,
    name: 'Express',
    icon: 'entypo:star',
  },
  {
    id: 8,
    name: 'PostgreSQL',
    icon: 'simple-icons:postgresql',
  },
  {
    id: 9,
    name: 'Git',
    icon: 'simple-icons:git',
  },
  {
    id: 10,
    name: 'Python',
    icon: 'simple-icons:python',
  },
  {
    id: 11,
    name: 'Django',
    icon: 'simple-icons:django',
  },
  {
    id: 12,
    name: 'Flask',
    icon: 'simple-icons:flask',
  },
]
