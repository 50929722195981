import React from 'react'
import styled from 'styled-components'

import { Heading, Section } from '../../styles/styled-components'

import { portfolio } from '../../data/portfolio'

const PortfolioContainer = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-wrap: wrap;
`

const PortfolioItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5rem;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 48%;

  @media (max-width: 1000px) {
    width: 100%;
  }
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
`

const Details = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 100%;
    align-items: center;
  }
`

const Title = styled.h4`
  font-size: ${({ theme: { fontSizes } }) => fontSizes.medium};

  @media (max-width: 1000px) {
    margin: 2rem 0 0;
  }
`

const ButtonContainer = styled.div`
  width: 27rem;
  margin: 2rem 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 400px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`

const Button = styled.a`
  background: ${({ theme: { colors } }) => colors.charcoal};
  color: ${({ theme: { colors } }) => colors.coral};
  width: 13rem;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.charcoal};
    background: ${({ theme: { colors } }) => colors.coral};
  }

  @media (max-width: 400px) {
    margin-top: 1rem;
  }
`

const Skills = styled.ul`
  margin: 0 0 2rem;
  padding: 0;
  list-style-position: inside;
  display: flex;
  flex-wrap: wrap;
`

const Skill = styled.li`
  margin-right: 2rem;
`

const Portfolio = () => {
  return (
    <Section>
      <Heading>Portfolio</Heading>
      <PortfolioContainer>
        {portfolio.map(item => (
          <PortfolioItem key={item.id}>
            <ImageContainer>
              <Image src={item.image} alt={item.name} />
            </ImageContainer>
            <Details>
              <Title>{item.name}</Title>
              <ButtonContainer>
                <Button href={item.liveDemo}>Live Demo</Button>
                <Button href={item.github}>Github</Button>
              </ButtonContainer>
              <Skills>
                {item.skills.map(skill => (
                  <Skill key={skill}>{skill}</Skill>
                ))}
              </Skills>
              <p>{item.description}</p>
            </Details>
          </PortfolioItem>
        ))}
      </PortfolioContainer>
    </Section>
  )
}

export default Portfolio
